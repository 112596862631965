<template>
  <div class="ueber-mich">
    <page-title slug="ueber-mich" alt="Über Mich">Über Mich</page-title>
    <v-page>
      <article class="text hyphenate" v-html="ueberMich.einleitung"></article>

      <h2 class="article-title">BERUFLICHES</h2>
      <article class="text hyphenate" v-html="ueberMich.berufliches"></article>

      <div class="content-img">
        <responsive-image
          :urls="{
            webp: [
              { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-1_640.webp') },
              { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-1_960.webp') },
              { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-1_1280.webp') },
              { size: 1596, url: require('@/assets/img/ueber-mich/ueber-mich-1_1596.webp') },
            ],
            jpg: [
              { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-1_640.jpg') },
              { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-1_960.jpg') },
              { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-1_1280.jpg') },
              { size: 1596, url: require('@/assets/img/ueber-mich/ueber-mich-1_1596.jpg') },
            ],
          }"
          sizes="(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw"
          alt="Portrait: Karl Grimmer"
        ></responsive-image>
      </div>

      <template v-if="ueberMich.ausbildungen && ueberMich.ausbildungen.length">
        <h2 class="article-title">AUS- UND WEITERBILDUNGEN</h2>
        <ul class="table hyphenate">
          <li v-for="({ jahre, ausbildung }, i) in ueberMich.ausbildungen" :key="i">
            <span v-if="jahre" class="zeit">{{ jahre }}</span>
            <span class="ereignis">{{ ausbildung }}</span>
          </li>
        </ul>
      </template>

      <div
        class="taetigkeiten"
        v-if="ueberMich.lehrtaetigkeiten && ueberMich.lehrtaetigkeiten.length"
      >
        <div>
          <responsive-image
            class="taetigkeiten-img"
            :urls="{
              webp: [
                { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-2_640.webp') },
                { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-2_960.webp') },
                { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-2_1280.webp') },
                { size: 1920, url: require('@/assets/img/ueber-mich/ueber-mich-2_1920.webp') },
              ],
              jpg: [
                { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-2_640.jpg') },
                { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-2_960.jpg') },
                { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-2_1280.jpg') },
                { size: 1920, url: require('@/assets/img/ueber-mich/ueber-mich-2_1920.jpg') },
              ],
            }"
            sizes="(min-width: 1280px) calc((min(1120px, min(calc(100vw - 128px), 1280px)) - 96px) / 5 * 2), (min-width: 1080px) calc((min(1120px, max(80vw, calc(100vw - 128px))) - 96px) / 5 * 2), min(400px, 100vw)"
            alt="Portrait: Karl Grimmer"
          ></responsive-image>
        </div>

        <div>
          <h2 class="article-title">LEHRTÄTIGKEITEN</h2>
          <ul class="table hyphenate">
            <li v-for="({ jahre, lehrtaetigkeit }, i) in ueberMich.lehrtaetigkeiten" :key="i">
              <span v-if="jahre" class="zeit">{{ jahre }}</span>
              <span class="ereignis">{{ lehrtaetigkeit }}</span>
            </li>
          </ul>
        </div>
      </div>

      <template v-if="ueberMich.publikationen && ueberMich.publikationen.length">
        <h2 class="article-title">PUBLIKATIONEN</h2>
        <ul class="list">
          <li
            class="text hyphenate"
            v-for="({ publikation }, i) in ueberMich.publikationen"
            :key="i"
            v-html="publikation"
          ></li>
        </ul>
      </template>

      <template v-if="ueberMich.kooperationspartner && ueberMich.kooperationspartner.length">
        <h2 class="article-title">KOOPERATIONSPARTNER</h2>
        <ul class="list">
          <li
            class="text hyphenate"
            v-for="({ kooperationspartner }, i) in ueberMich.kooperationspartner"
            :key="i"
            v-html="kooperationspartner"
          ></li>
        </ul>
      </template>

      <template
        v-if="ueberMich.ehrenamtlicheTaetigkeiten && ueberMich.ehrenamtlicheTaetigkeiten.length"
      >
        <h2 class="article-title">EHRENAMTLICHE TÄTIGKEIT</h2>
        <ul class="list">
          <li
            class="text hyphenate"
            v-for="({ ehrenamtlicheTaetigkeit }, i) in ueberMich.ehrenamtlicheTaetigkeiten"
            :key="i"
            v-html="ehrenamtlicheTaetigkeit"
          ></li>
        </ul>
      </template>
    </v-page>
  </div>
</template>

<script>
import axios from 'axios'

import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'

import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'UeberMich',
  components: {
    PageTitle,
    VPage,
    ResponsiveImage,
  },
  data() {
    return {
      ueberMich: {},
    }
  },
  async beforeRouteEnter(to, from, next) {
    const res = await axios.get('/ueber-mich')
    next(vm => (vm.ueberMich = res.data.data))
  },
}
</script>

<style scoped>
.table {
  max-width: var(--text-width);
  margin: 0 auto;
}

.table li {
  margin-top: 1em;
  font-family: var(--font-sans);
  font-weight: var(--weight-sans-light);
  font-size: var(--font-size-regular);
  line-height: var(--leading-regular);
}

@media all and (min-width: 560px) {
  .table li {
    display: flex;
  }
}

.table .zeit {
  display: block;
  font-weight: var(--weight-sans-semibold);
  font-size: var(--font-size-reduced);
  color: hsl(0, 0%, 40%);
}

@media all and (min-width: 560px) {
  .table .zeit {
    display: inline;
    flex: 0 0 auto;
    width: 8em;
  }
}

.list li:not(:first-child) {
  margin-top: var(--leading-regular);
}

.taetigkeiten-img {
  display: block;
  width: min(400px, 100%);
  margin: 4em auto 0;
}

@media all and (min-width: 1080px) {
  .taetigkeiten {
    display: grid;
    grid-template-columns: 2fr 3fr;
    column-gap: 96px;
    align-items: center;
    margin: 4em auto 0;
    width: min(1120px, 100%);
  }

  .taetigkeiten-img {
    width: initial;
    margin: initial;
  }

  .taetigkeiten .article-title:first-of-type {
    margin-top: 0;
  }
}
</style>
