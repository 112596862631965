var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ueber-mich"},[_c('page-title',{attrs:{"slug":"ueber-mich","alt":"Über Mich"}},[_vm._v("Über Mich")]),_c('v-page',[_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.ueberMich.einleitung)}}),_c('h2',{staticClass:"article-title"},[_vm._v("BERUFLICHES")]),_c('article',{staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(_vm.ueberMich.berufliches)}}),_c('div',{staticClass:"content-img"},[_c('responsive-image',{attrs:{"urls":{
          webp: [
            { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-1_640.webp') },
            { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-1_960.webp') },
            { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-1_1280.webp') },
            { size: 1596, url: require('@/assets/img/ueber-mich/ueber-mich-1_1596.webp') } ],
          jpg: [
            { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-1_640.jpg') },
            { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-1_960.jpg') },
            { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-1_1280.jpg') },
            { size: 1596, url: require('@/assets/img/ueber-mich/ueber-mich-1_1596.jpg') } ],
        },"sizes":"(min-width: 1280px) min(960px, min(calc(100vw - 128px), 1280px)), (min-width: 480px) min(960px, max(80vw, calc(100vw - 128px))), 100vw","alt":"Portrait: Karl Grimmer"}})],1),(_vm.ueberMich.ausbildungen && _vm.ueberMich.ausbildungen.length)?[_c('h2',{staticClass:"article-title"},[_vm._v("AUS- UND WEITERBILDUNGEN")]),_c('ul',{staticClass:"table hyphenate"},_vm._l((_vm.ueberMich.ausbildungen),function(ref,i){
        var jahre = ref.jahre;
        var ausbildung = ref.ausbildung;
return _c('li',{key:i},[(jahre)?_c('span',{staticClass:"zeit"},[_vm._v(_vm._s(jahre))]):_vm._e(),_c('span',{staticClass:"ereignis"},[_vm._v(_vm._s(ausbildung))])])}),0)]:_vm._e(),(_vm.ueberMich.lehrtaetigkeiten && _vm.ueberMich.lehrtaetigkeiten.length)?_c('div',{staticClass:"taetigkeiten"},[_c('div',[_c('responsive-image',{staticClass:"taetigkeiten-img",attrs:{"urls":{
            webp: [
              { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-2_640.webp') },
              { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-2_960.webp') },
              { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-2_1280.webp') },
              { size: 1920, url: require('@/assets/img/ueber-mich/ueber-mich-2_1920.webp') } ],
            jpg: [
              { size: 640, url: require('@/assets/img/ueber-mich/ueber-mich-2_640.jpg') },
              { size: 960, url: require('@/assets/img/ueber-mich/ueber-mich-2_960.jpg') },
              { size: 1280, url: require('@/assets/img/ueber-mich/ueber-mich-2_1280.jpg') },
              { size: 1920, url: require('@/assets/img/ueber-mich/ueber-mich-2_1920.jpg') } ],
          },"sizes":"(min-width: 1280px) calc((min(1120px, min(calc(100vw - 128px), 1280px)) - 96px) / 5 * 2), (min-width: 1080px) calc((min(1120px, max(80vw, calc(100vw - 128px))) - 96px) / 5 * 2), min(400px, 100vw)","alt":"Portrait: Karl Grimmer"}})],1),_c('div',[_c('h2',{staticClass:"article-title"},[_vm._v("LEHRTÄTIGKEITEN")]),_c('ul',{staticClass:"table hyphenate"},_vm._l((_vm.ueberMich.lehrtaetigkeiten),function(ref,i){
          var jahre = ref.jahre;
          var lehrtaetigkeit = ref.lehrtaetigkeit;
return _c('li',{key:i},[(jahre)?_c('span',{staticClass:"zeit"},[_vm._v(_vm._s(jahre))]):_vm._e(),_c('span',{staticClass:"ereignis"},[_vm._v(_vm._s(lehrtaetigkeit))])])}),0)])]):_vm._e(),(_vm.ueberMich.publikationen && _vm.ueberMich.publikationen.length)?[_c('h2',{staticClass:"article-title"},[_vm._v("PUBLIKATIONEN")]),_c('ul',{staticClass:"list"},_vm._l((_vm.ueberMich.publikationen),function(ref,i){
          var publikation = ref.publikation;
return _c('li',{key:i,staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(publikation)}})}),0)]:_vm._e(),(_vm.ueberMich.kooperationspartner && _vm.ueberMich.kooperationspartner.length)?[_c('h2',{staticClass:"article-title"},[_vm._v("KOOPERATIONSPARTNER")]),_c('ul',{staticClass:"list"},_vm._l((_vm.ueberMich.kooperationspartner),function(ref,i){
          var kooperationspartner = ref.kooperationspartner;
return _c('li',{key:i,staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(kooperationspartner)}})}),0)]:_vm._e(),(_vm.ueberMich.ehrenamtlicheTaetigkeiten && _vm.ueberMich.ehrenamtlicheTaetigkeiten.length)?[_c('h2',{staticClass:"article-title"},[_vm._v("EHRENAMTLICHE TÄTIGKEIT")]),_c('ul',{staticClass:"list"},_vm._l((_vm.ueberMich.ehrenamtlicheTaetigkeiten),function(ref,i){
          var ehrenamtlicheTaetigkeit = ref.ehrenamtlicheTaetigkeit;
return _c('li',{key:i,staticClass:"text hyphenate",domProps:{"innerHTML":_vm._s(ehrenamtlicheTaetigkeit)}})}),0)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }